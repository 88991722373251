.result {
}

.result-title {
    color: rgb(0, 106, 143);
    font-weight: normal;
}

.result-publisher {
    font-style: italic;
}


.navigation-section {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.navigation-buttons-group {
    display: flex;
    padding: unset;
    margin-top: 5px;
    margin-bottom: 10px;
}

.navigation-button {
    border-radius: 5px;
    text-align: center;
    list-style-type: none;
    padding: 1px 3px 1px 3px;
    font-size: 11px;
    margin-right: 6px;
    min-width: 2em;
    color: rgb(108, 117, 125);
    border: solid 1px;
}

.navigation-button-active {
    color: white;
    background-color: #6c757d;
}


.highlight-entities {
    padding: 2px;
    margin-right: 5px;
    font-size: 0.9em;
    border: 0px;
    line-height: 1em;
    border-radius: 7px;
    vertical-align: inherit;
}
