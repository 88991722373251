.popoverContent {
    background-color: white;
    padding: 5px;
    border-radius: 5px;
    width: max-content;
}

.entity {
}

.highlight-entity {
    padding: 1px;
    margin: 0px;
    font-size: 1em;
    border: 0px;
    background-color: lightgrey;
    color: black;
    line-height: 1em;
    border-radius: 5px;
    vertical-align: inherit;
}

.popover-inner {
    border: solid 1px;
    border-color: grey;
    width: max-content;
}

.badge-kb {
    color: #fff;
    background-color: #8888aa;
    margin-right: .4em;
    padding: .25em .25em .25em .4em;
    border-radius: 10rem;
    display: inline-block;
    font-size: 75%;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-style: normal;
}

.badge-kb:hover {
    transform: translate(0px, -0px) scale(1.05);
    background-color: #9999bb;
}

.entity-label {
    font-size: 90%;
}
